// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables
// @import "~/node_modules/bootstrap/scss/_variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,700;1,700&display=swap');

$enable-negative-margins: true; 

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1364px,
  xxxl: 1600px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
);

// Theme colors


$green: #00A34C;
$dark-green:#004438;
$teal:#006853;
$black: #383838;
$white: #FCFCFC;
$yellow: #EBE718;

$primary: $teal;
$secondary: $yellow;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "green": $green,
  "dark-green": $dark-green,
  "teal": $teal,
  "black": $black,
  "white": $white,
  "yellow": $yellow,
);

// Typography
$headings-color: $teal;

$h1-font-size: 4rem;        //72px
$h2-font-size: 3.333rem;    //60px
$h3-font-size: 2.667rem;    //48px
$h4-font-size: 2rem;        //36px
$h5-font-size: 1.667rem;    //30px
$h6-font-size: 1.333rem;    //24px
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

// -------- Fonts---------//

//
$primary-text-emphasis: #fff;
$body-emphasis-color: #fff;

$font-family-base: "Public Sans", sans-serif;
$font-size-base: 1rem;
$font-size-root: 18px;

// Links
$link-color: $teal;
$link-decoration: none;


// Navbar
$navbar-light-color:$white;
$navbar-light-icon-color: $white;
$navbar-light-hover-color: $white;
$nav-link-font-size: 1.111rem;
// $nav-link-color: $white;
// $nav-link-hover-color: $yellow;
// Pagination
$accordion-bg: transparent;
$accordion-button-active-bg: #F9F9F9;
$accordion-button-hover-bg: transparent;

$accordion-button-active-color: $green;
$accordion-icon-active-color: $green;
// $accordion-icon-transform:rotate(90deg);
// $accordion-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="15" viewBox="0 0 18 15"><defs><clipPath id="a"><rect width="18" height="15" fill="#393b3c"/></clipPath></defs><g clip-path="url(#a)"><path d="M17.623,6.805a1.285,1.285,0,0,1,0,1.818l-6.429,6.429a1.286,1.286,0,0,1-1.818-1.818L13.611,9H1.286a1.286,1.286,0,1,1,0-2.571H13.611L9.377,2.194A1.286,1.286,0,0,1,11.195.376Z" fill="#{$black}"/></g></svg>');
// $accordion-button-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="15" viewBox="0 0 18 15"><defs><clipPath id="a"><rect width="18" height="15" fill="#393b3c"/></clipPath></defs><g clip-path="url(#a)"><path d="M17.623,6.805a1.285,1.285,0,0,1,0,1.818l-6.429,6.429a1.286,1.286,0,0,1-1.818-1.818L13.611,9H1.286a1.286,1.286,0,1,1,0-2.571H13.611L9.377,2.194A1.286,1.286,0,0,1,11.195.376Z" fill="#{$accordion-icon-active-color}"/></g></svg>');

// Button
// $btn-font-size:0.944rem;
$btn-border-radius:0px;
$btn-padding-x:2rem;
// $btn-font-family:$font-secondary;

$container-padding-x:3rem;