@import "variables";

.block-image-content, .block-content-image{
    picture{
        display: block;
        position: relative;
        &::after{
            content:"";
            position: absolute;
    
            top: 10%;
            height: 80%;
            right: 0%;
            clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
            background-color: $dark-green;
            z-index: -1;
            display: block;
            width: 70vw;
            @include media-breakpoint-down(md) {
                width: 100vw !important;
            }
        }
    }
}
.block-content-image picture::after{
    background-color: $green;
    left:0;
    right: unset;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
}
.block-testimonials{
    background-color: #F9F9F9;
    .splide__pagination{
        margin-top: 2rem;
        padding: 0;
        position: relative;
        justify-content: flex-start;
        gap:.5rem;
        .splide__pagination__page {
            background-color:  #A8A8A8;
            height: 9px;
            width: 9px;
            padding:0;
            opacity: 1;
            &.is-active{
                background-color:  #373737;
                transform: none!important;
            }
        }
       
    }
}
.block-instafeed{
    svg{
        height: 24px;
        width: 24px;
    }
}
.block-service{
    picture{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1 / 1;
        width: 100%;
        img, title{
            z-index: 2;
        }
        img{
            aspect-ratio: 1 / 1;
            overflow: visible;
            object-fit: contain;
            transform: scale(1.1);
            @include media-breakpoint-down(md) {
                transform: scale(1);
            }
        }
        &::after{
            content:"Learn more";
            position: absolute;
            background-color: $green;
            font-family: "azo-sans-web", sans-serif;
            font-size: 24px;
            font-weight: 700;
            padding: .75rem 2rem;
            z-index: 10;
            color:$yellow;
            transition: color .3s ease-in-out, background-color .3s ease-in-out;
        }
        &::before{
            content: "";
            position: absolute;
            clip-path: polygon(0 0, 85% 0%, 100% 100%, 15% 100%);
            z-index: 0;
            display: block;
            width: 70%;
            height: auto;
            aspect-ratio: 4 / 5;
            background-color: #097B3E;
            transition: color .3s ease-in-out, background-color .3s ease-in-out
        }
    }
    a:hover picture{
        &::after{
            background-color: $yellow;
            color:$dark-green;
        }
        &::before{
            background-color: $dark-green;
        }
    }
}