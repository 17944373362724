@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later
@import "variables";
@import "page";
@import "header";
@import "footer";
@import "blocks";
@import "form";
// Bootstrap library
@import "~bootstrap/scss/bootstrap";
@import '@splidejs/splide/css';

.trapezoid{
    position: relative;
    &::after{
        content: "";
        position: absolute;
        right: 0%;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 30px 100%);
        
        z-index: -1;
        display: block;
        width: calc(100% + 30px);
        height: 100%;
    }
    &-green{
        &::after{
            background-color: $green;
        }
    }
}
.trapezoid-img{
    position: relative;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 7% 100%);
}

.dunbar{
    font-family: "dunbar-tall", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.astounder{
    font-family: "astounder-squared-lc-bb", sans-serif;
    font-weight: 400;
    font-style: italic;
}
.azo{
    font-family: "azo-sans-web", sans-serif;
    font-style: normal;
}
.lead{
    font-size: 20px;
    font-weight: 700;
}

.dropdown-toggle{
    display: none;
}

.btn {
    &.btn-yellow{
        font-family: "azo-sans-web", sans-serif;
        color:$teal;
    }
    &.btn-link{
        color: $green;
        padding-left: 0;
        padding-right: 0;
        font-family: "azo-sans-web", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        &::after{
            content:url('../assets/img/chevron.svg');
            display: inline-block;
            margin-left: 20px;
        }
      
    }
}
.has-collapse-button > .btn.btn-link{
    &::after{
        content:""!important;
    }
}

.offcanvas-body{
    background-color: $white;
}
.pagination{
    margin-top: 2rem;
    & > .page-item:first-child{
        margin-right: auto;
    }
    & > .page-item:last-child{
        margin-left: auto;
    }
    .page-link.pager-arrow-a{
        background-color: transparent;
        border: none;
        font-weight: 500;
        color: #383838;
        display: flex;
        gap: 1rem;
        &.arrow-next .pager-arrow-icon{
            content:url('../assets/img/chevron-next.svg');
        }
        &.arrow-prev .pager-arrow-icon{
            content:url('../assets/img/chevron-prev.svg');
        }
       
    }
    & > .page-item.disabled{
        opacity: 0;
        pointer-events: none;
    }
    .page-link:hover{
        background-color:$dark-green;
        color: $white;
    }
}
.site-links svg:hover{
    animation: hithere 1.2s ease infinite;
}
@keyframes hithere {
    30% { transform: scale(1.1); }
    40%, 60% { transform: rotate(-10deg) scale(1.1); }
    50% { transform: rotate(10deg) scale(1.1); }
    70% { transform: rotate(0deg) scale(1.1); }
    100% { transform: scale(1); }
}


.wobble-hover:hover{   
    animation: wobble 2s ease infinite;
}
  @keyframes wobble {
    0% { transform: translateY(0%); }
    15% { transform: translateY(-15%) rotate(-7deg); }
    15% { transform: translateY(-18%) rotate(8deg); }
    30% { transform: translateY(10%) rotate(-8deg); }
    45% { transform: translateY(15%) rotate(8deg); }
    60% { transform: translateY(-10%) rotate(-7deg); }
    75% { transform: translateY(-5%) rotate(6deg); }
    100% { transform: translateY(0%); }
  }

.product-card{
    .title{
        font-size: 2.222rem;
    }
    .label{
        color: #A8A8A8;
        margin-bottom: 0;
    }
    .product-icon{
        background-color:#F9F9F9;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        transition: background-color .3s ease-in-out;
        img{
            transition: transform .3s ease-in-out, filter .3s ease-in-out;;
        }
    }

    &:hover{
        .product-icon{
            background-color:$dark-green;
            img{
                transform: scale(1.1);
            }
        }
        .man{
            filter: brightness(0) invert(1);
        }
    }
}

.news-card{
    .news-date{
        color:#383838;
    }
    .overlay{
        background-color: #fff;
        transition: background-color .3s ease-in-out;
        &::after{
            content:"Read more";
            position: absolute;
            background-color: $yellow;
            font-family: "azo-sans-web", sans-serif;
            font-size: 24px;
            font-weight: 700;
            padding: .75rem 2rem;
            z-index: 10;
            color:$green;
            left:0;
            bottom:0;
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }
        img{
            mix-blend-mode: multiply;
        }
    }
    &:hover{
        .overlay{
            background-color: #A8A8A8;
            &::after{
                opacity: 1;
            }
        }
    }
}


.btn{
    &.btn-yellow, &.btn-green, &.btn-teal, &.btn-dark-green, &.btn-primary{
        &:hover {
            -webkit-animation: wobble-hor-bottom 0.8s both;
            animation: wobble-hor-bottom 0.8s both;
        }
    } 
}
/* ----------------------------------------------
 * Generated by Animista on 2024-8-22 11:50:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(-6deg);
              transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(6deg);
              transform: translateX(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3.6deg);
              transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(2.4deg);
              transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(-1.2deg);
              transform: translateX(-6px) rotate(-1.2deg);
    }
  }
  @keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(-6deg);
              transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(6deg);
              transform: translateX(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3.6deg);
              transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(2.4deg);
              transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(-1.2deg);
              transform: translateX(-6px) rotate(-1.2deg);
    }
  }
.share-bar{
    svg{
        width: 24px;
        height: 24px;
    }
}
.header-summary{
    max-width: 1100px;
}

.accordion-button{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 2rem;
    font-size: 1.333rem;
    font-weight: 700;
    // background-color: #F9F9F9;
    &::after{
        margin-left: 0;
    }
    // &.collapsed{
    //     background-color: transparent;
    // }
}
.accordion-collapse{
    background-color: #F9F9F9;
}

.navbar-toggler:focus {
    box-shadow: none!important;
}
.really-big-text {
    font-size: clamp(50px,14vw,120px)
}
  
