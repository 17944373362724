.js-float-wrap{
    position:relative;
    margin-bottom: 1.25rem;
    label{
        position: absolute;
        top:8px;
        color: inherit;
        padding-left: 20px;
        max-width: 100%;
        box-sizing: border-box;
        transition: all 1s ease;
        z-index: 1;
        font-size: 18px;
        color: #A8A8A8;
    }
    &.focused, &.populated, &.fixed{
        label{
            top:3px;
            line-height: 1;
            font-size: 0.5em;
            padding-left: 15px;
            color: #373737;
        }
    }
}
input.form-control, select.form-select, textarea.form-control{
    border-radius: 0!important;
    border:none;
    border-bottom: 1px solid $green;
}
.recaptcha-notice{
    text-align: center;
    padding-top: 1rem;
}
// input[type=date]:empty{ 
//     color: transparent!important;
// }
